import React from "react";
import "./Ad.scss";
function Ad() {
  return (
    <div className="ad">
      <h3>Lorem ipsum dolor sit amet ipsum</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut <br></br>labore et dolore magna aliqua. dolor sit
        amet eiusmod tempor incididunt
      </p>
    </div>
  );
}

export default Ad;

import React from "react";
import "./Favorites.scss";
function Favorites() {
  return (
    <div className="favorites">
      <h3>Omiljeno</h3>
    </div>
  );
}

export default Favorites;

import React from "react";
import "./Cities.scss";

const CityLinks = ({ prefix }) => {
  const cities = [
    'Beogradu',
    'Novom Sadu',
    'Nišu',
    'Kragujevcu',
    'Subotici',
    'Zrenjaninu',
    'Kruševcu',
    'Pančevu',
    'Jagodini',
    'Vranju',
    // Note: Zlatibor is handled separately below
  ];

  const specialCases = [
    { name: 'Zlatiboru', prefix: 'Izdavanje apartmana', preposition: 'na' } // Special case for Zlatibor
  ];

  const generateSlug = (city) => {
    return `/search/${city.toLowerCase().replace(/ /g, '-')}`;
  };

  return (
    <ul className="city-links">
      {cities.map((city, index) => (
        <li key={index}>
          <a href={generateSlug(city)}>{`${prefix} u ${city}`}</a>
        </li>
      ))}
      {specialCases.map((caseItem, index) => (
        <li key={`special-${index}`}>
          <a href={generateSlug(caseItem.name)}>{`${caseItem.prefix} ${caseItem.preposition} ${caseItem.name}`}</a>
        </li>
      ))}
    </ul>
  );
};

function Cities() {
  return (
    <div className="cities-container">
      <div className="cities-column">
        <h3>Rentiranje Nekretnina u Srbiji</h3>
        <CityLinks prefix="Izdavanje stanova" />
      </div>
      <div className="cities-column">
        <h3>Poslovni Prostori u Srbiji</h3>
        <CityLinks prefix="Izdavanje poslovnog prostora" />
      </div>
      <div className="cities-column">
        <h3>Ostale Nekretnine u Srbiji</h3>
        <CityLinks prefix="Izdavanje nekretnina" />
      </div>
    </div>
  );
}

export default Cities;

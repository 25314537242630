import React from "react";
import "./MiniFooter.scss";
import logo from "../../assets/logo.png";
function MiniFooter() {
  return (
    <div className="mini-footer">
      <div className="mini-footer__left">
        {/* <img src={logo} alt="" /> */}
        <ul>
          <li>
            <a href="#">Uslovi Korišćenja</a>
          </li>
          <li>
            <a href="#">Politika Privatnosti</a>
          </li>
          <li>
            <a href="#">Najčešća Pitanja</a>
          </li>
        </ul>
      </div>
      <span>Rentiranje.com Sva prava zadržana 2024</span>
    </div>
  );
}

export default MiniFooter;
